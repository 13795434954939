import croppedPhoto from "../../../../src/images/MAX_BLANK_200.png";
import uncroppedPhoto from "../../../../src/images/MAX_DARK_200.png";
import croppedPhotoFull from "../../../../src/images/MAX_BLANK.png";
import uncroppedPhotoFull from "../../../../src/images/MAX_DARK.png";
import * as React from 'react';
export default {
  croppedPhoto,
  uncroppedPhoto,
  croppedPhotoFull,
  uncroppedPhotoFull,
  React
};